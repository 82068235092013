export const freshman = {
  heading: "First Year Experiences",
  content:
    "Some accounts of the experience of the freshman year of these students in IITR.",
}

export const alumni = {
  heading: "Archive Experiences",
  content:
    "Some accounts of the experience of the freshman year of these students in IITR.",
}
